
import { api } from "@/api/api";
import {
  ApiGetGuestEmployeewithMemberOrganizationDto,
  ApiGetMemberOrganizationDto,
  ApiGetUnreadCourseApplicationsDto,
} from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { DashboardRouteNames } from "@/shared/enums/RouteNames/dashboardRouteNames.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { formatLocalizedDateP } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { checkMemberOrgLevel } from "@/shared/helpers/memberOrgHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { capitalize } from "@/shared/helpers/textHelpers";
import { PropType, defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  components: { BaseLayout, BaseTableFiltered },
  name: "PreApprovedParticipantsPage",
  props: {
    groupBy: String as PropType<string | null>,
  },
  setup() {
    const selectedRows = ref<ApiGetUnreadCourseApplicationsDto[]>([]);
    const newApplications = ref<ApiGetUnreadCourseApplicationsDto[]>([]);
    const isFilterOpen = ref(false);
    const dashboardLink = () => useNavigateBack(DashboardRouteNames.Home);
    const preApprovedParticipants = ref<ApiGetUnreadCourseApplicationsDto[]>([]);
    const memberOrgazations = ref<ApiGetMemberOrganizationDto[]>([]);
    const hierarchyLevel = ref<string>("");
    const currentGuestUser = ref<ApiGetGuestEmployeewithMemberOrganizationDto>();
    const currentGroupBy = ref<string | null>(null);

    const getCurrentUser = async () => {
      currentGuestUser.value = (await api.guestside.getGuestUserCurrentGuestEmployeeAsync()).data;
      hierarchyLevel.value = await checkMemberOrgLevel(currentGuestUser.value.memberOrganization?.id || 0);
    };

    const getParticipantsLink = (courseId: string) => ({
      name: SingleCourseRouteNames.CourseParticipants,
      params: {
        id: courseId,
      },
    });

    const getPreApprovedParticipants = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const response = (await api.guestside.getPreApprovedParticipants()).data;

        if (!response.length) {
          return;
        }

        preApprovedParticipants.value = response;
      });
    };

    const getMemberOrganizations = async () => {
      memberOrgazations.value = (await api.guestside.getGuestUserMemberOrganizations()).data;
    };

    const getMemberOrganizationNameById = (id?: number) => {
      if (id === undefined || id === null) {
        return "Ingen medlemsorganisasjon";
      }
      return memberOrgazations.value?.find((x) => x.id === id)?.name || "Ukjent navn";
    };

    onMounted(async () => {
      await getCurrentUser();
      await getPreApprovedParticipants();
      await getMemberOrganizations();
    });

    return {
      headers,
      isFilterOpen,
      selectedRows,
      newApplications,
      dashboardLink,
      getParticipantsLink,
      formatLocalizedDateP,
      hasMemberOrgAccess,
      preApprovedParticipants,
      capitalize,
      getMemberOrganizationNameById,
      currentGroupBy,
    };
  },
});

const headers = [
  {
    text: "Id",
    value: "courseId",
  },
  {
    text: "Handlinger",
    value: "actions",
  },
  {
    text: "Navn på søker/deltaker",
    value: "applicantName",
  },
  {
    text: hasMemberOrgAccess ? "Søknadsdato" : "Søknads-/Påmeldingsdato",
    value: "applicationSentDate",
  },
  {
    text: "Påmeldingsstatus",
    value: "applicationStatus",
  },
  {
    text: "Tittel",
    value: hasMemberOrgAccess ? "externalTitle" : "courseName", // Show external title for guest users
  },
  {
    text: "Sted",
    value: "areaName",
  },
  {
    text: "Administrator",
    value: "lecturerName",
  },
  {
    text: "Startdato",
    value: "startDate",
  },
  {
    text: "Påmeldingsfrist",
    value: "enrollmentDeadline",
  },
  {
    text: "Metode",
    value: "appliedViaForm",
  },
];
