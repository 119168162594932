
import { api } from "@/api/api";
import { ApiGetUnreadCourseApplicationsDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { DashboardUser } from "@/shared/constants/dashboardUser";
import { DashboardRouteNames } from "@/shared/enums/RouteNames/dashboardRouteNames.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { formatLocalizedDateP } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { useRouter } from "@/shared/useHelpers";
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import { hasMemberOrgAccess, useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";

export default defineComponent({
  components: { BaseLayout, BaseTableFiltered },
  name: "NewApplicationsPage",
  props: {
    groupBy: String as PropType<string | null>,
  },
  setup(props) {
    const router = useRouter();
    const restrictedAccessApi = useRestrictedAccessApi();
    const selectedRows = ref<ApiGetUnreadCourseApplicationsDto[]>([]);
    const newApplications = ref<ApiGetUnreadCourseApplicationsDto[]>([]);
    const isFilterOpen = ref(false);

    const currentGroupBy = computed({
      set: (groupBy: string | null) => {
        if (groupBy) {
          router.replace({ params: { groupBy } });
        } else {
          router.replace({ name: DashboardRouteNames.NewApplications });
        }
      },
      get: () => props.groupBy || null,
    });

    const dashboardLink = () => useNavigateBack(DashboardRouteNames.Home);

    const getParticipantsLink = (courseId: string) => ({
      name: SingleCourseRouteNames.CourseParticipants,
      params: {
        id: courseId,
      },
    });

    const markAsRead = async (selectedApplications: ApiGetUnreadCourseApplicationsDto[]) => {
      const applications = selectedApplications.map(({ courseId, applicantUserId }) => ({
        courseId,
        userId: applicantUserId,
      }));

      await api.dashboard.markCourseApplicationsAsReadAsync(applications);
      await getNewApplications();
      selectedRows.value = [];
    };

    const getNewApplications = () => {
      const userId = Number(window.sessionStorage.getItem(DashboardUser.USER_ID));
      globalLoadingWrapper({ blocking: true }, async () => {
        newApplications.value = (await restrictedAccessApi.getCourseApplications({ userId })).data.map(
          (courseItem, index) => ({ id: index, ...courseItem })
        );

        if (!hasMemberOrgAccess) {
          newApplications.value = newApplications.value.filter((courseItem) => courseItem.appliedViaForm);
        }
      });
    };

    onMounted(() => {
      getNewApplications();
    });

    return {
      currentGroupBy,
      headers: geatHeaders(hasMemberOrgAccess),
      isFilterOpen,
      selectedRows,
      newApplications,
      dashboardLink,
      markAsRead,
      getParticipantsLink,
      formatLocalizedDateP,
      hasMemberOrgAccess,
    };
  },
});

const geatHeaders = (hasMemberOrgAccess: boolean) => [
  {
    text: "Id",
    value: "courseId",
  },
  {
    text: "Handlinger",
    value: "actions",
  },
  {
    text: "Navn på søker/deltaker",
    value: "applicantName",
  },
  {
    text: hasMemberOrgAccess ? "Søknadsdato" : "Søknads-/Påmeldingsdato",
    value: "applicationSentDate",
  },
  {
    text: "Påmeldingsstatus",
    value: "applicationStatus",
  },
  {
    text: "Tittel",
    value: hasMemberOrgAccess ? "externalTitle" : "courseName", // Show external title for guest users
  },
  {
    text: "Sted",
    value: "areaName",
  },
  {
    text: "Administrator",
    value: "lecturerName",
  },
  {
    text: "Startdato",
    value: "startDate",
  },
  {
    text: "Påmeldingsfrist",
    value: "enrollmentDeadline",
  },
  {
    text: "Metode",
    value: "appliedViaForm",
  },
];
